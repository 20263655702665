import urlJoin from "url-join"

type LoaderParams = {
  src: string
  width: number
  quality?: number
}
export default function cloudflareLoader({
  src,
  width,
  quality,
}: LoaderParams) {
  if (process.env.NODE_ENV == "development") {
    return urlJoin(src, `?w=${width}`)
  }
  const params = [`width=${width}`, `quality=${quality || 75}`, "format=auto"]
  return `https://static.store.app/cdn-cgi/image/${params.join(",")}/${src}`
}
